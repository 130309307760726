import { Toolbar, useRecordContext } from "react-admin";
import { Stack } from "@mui/material";
import React from "react";
import { Question } from "../../api";
import ArchiveDialogButton from "../actions/ArchiveDialogButton";
import AssignDialogButton from "../actions/AssignDialogButton";
import ValidateAnswerDialogButton from "../actions/ValidateAnswerDialogButton";
import DraftValidatedAnswerDialogButton from "../actions/DraftValidatedAnswerDialogButton";

export default function QuestionEditToolbar() {
  const record = useRecordContext<Question>();
  if (
    !record ||
    ![
      record.can_transition_to_archived,
      record.can_transition_to_assigned,
      record.can_transition_to_answer_validated,
      record.can_transition_back_to_assignment_accepted,
    ].includes(true)
  )
    return null;

  return (
    <Toolbar>
      <Stack spacing={2} direction="row">
        {record.can_transition_to_archived && <ArchiveDialogButton />}
        {record.can_transition_to_assigned && <AssignDialogButton />}
        {record.can_transition_back_to_assignment_accepted && <DraftValidatedAnswerDialogButton />}
        {record.can_transition_to_answer_validated && <ValidateAnswerDialogButton />}
      </Stack>
    </Toolbar>
  );
}
