import { ResourceCallbacks } from "ra-core/src/dataProvider/withLifecycleCallbacks";

export type Product = {
  id: number;
  name: string;
  collaborator_type: "cse" | "employee" | null;
  customer_id: number | null;
  max_parallel_questions_per_member: number | null;
  forbidden_question_tag_ids: string[];
  restricted_question_tag_ids: string[];
  max_total_consultations_per_member: number | null;
  max_total_questions_per_member: number | null;
  // Note that this type does not map the api. The domain wrapping is needed because passing empty source
  // to SimpleFormIterator triggers react-admin warnings
  onboarding_domains: { domain: string }[];
  onboarding_code?: string;
};

export const productHandlers: ResourceCallbacks[] = [
  {
    resource: "staff/products",
    beforeSave: async (data: Product) => {
      return { ...data, onboarding_domains: data.onboarding_domains.map((domain) => domain.domain) };
    },
    afterRead: async (record) => {
      return { ...record, onboarding_domains: record.onboarding_domains.map((domain: string) => ({ domain })) };
    },
  },
];
